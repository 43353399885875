
import { Component, Vue } from "vue-property-decorator"
import { ConsultantFragmentFragment, FindConsultantQuery, FindConsultantQueryQuery } from "@/gql"
import { FetchResult } from "apollo-link"
import AppDetailPage from "@/components/layout/AppDetailPage.vue"
import Loader from "@/components/widgets/common/Loader.vue"

@Component({
  components: {
    AppDetailPage,
    Loader,
  },
})
export default class BasicProfile extends Vue {
  readonly query = FindConsultantQuery
  consultant: ConsultantFragmentFragment | null = null

  onFindConsultantComplete(result: FetchResult<FindConsultantQueryQuery>) {
    if (result.data && result.data.findConsultant) {
      this.consultant = result.data.findConsultant
    }
  }
}
